import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import styled from 'styled-components'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography, Heading1, Heading3, Heading4 } from '../styles/Typography'

const RelatedItemLink = styled(Link)`
  position: relative;
  img {
      margin-bottom: 0.5rem;
      border-radius: 1rem;
      transition: all 0.2s ease-out;
    }
  h4 {
    box-shadow: 0.2rem 0.2rem 0.25rem #00000033;
    margin-top: -45px;
    margin-left: 1rem;
    margin-right: 1rem;
    background: #ffffffdd;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    transition: all 0.2s ease-out;
  }
  &:hover {
    h4 {
      text-decoration: underline;
      text-decoration: underline;
      transform: translateY(-5px);
    }
    /* img {
        transform: translateY(5px)
    } */
  }
`

function RelatedSidebarItem ({ item }) {
  return (
    <RelatedItemLink to={`/recipe/${item.slug.current}`}>
      {item.mainImage && item.mainImage.asset && (
        <img
          src={imageUrlFor(buildImageObj(item.mainImage))
            .width(320)
            .maxWidth(320)
            .height(Math.floor((9 / 16) * 320))
            .quality(100)
            .sharpen(20)
            .fit('crop')
            .url()}
          alt={item.mainImage.alt}
        />
      )}
      <Heading4 className='related-recipes-title'>{item.title}</Heading4>
    </RelatedItemLink>
  )
}

export default RelatedSidebarItem
