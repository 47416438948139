import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import BlockContent from './block-content'
import Container from './container'
import RoleList from './role-list'
import styled from 'styled-components'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import PageTitle from '../components/page-title'
import { typography, Heading1, Heading3, Heading4 } from '../styles/Typography'
import RelatedSidebarItem from '../components/related-sidebar-item'

const RecipeWrapper = styled.article` 
  /* no styles yet */
`
const MainImage = styled.div`
  position: relative;
  background: #eee;
  padding-bottom: calc(9 / 16 * 100%);

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
`

const RecipeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* grid-column-gap: 2em; */
  padding: 0 0 2rem 0rem;

  @media ${device.mediaMinLarge} {
    grid-template-columns: 4fr 2fr;
    padding: 0 0 0 2rem;
  } 
`

const MainContent = styled.div`
  padding: 2rem 3rem 2rem 0;
  margin-bottom: 2rem;
  @media ${device.mediaMinLarge} {
    box-shadow: rgb(199 199 199 / 62%) -12px 0px 10px -6px inset;
    margin-bottom: 0;
  } 
  

  & a {
    color: ${colors.colorAccent};

    @media (hover: hover) {
      &:hover {
        color: inherit;
      }
    }
  }

  .recipe-block-content {
    ul {
      padding: 0 1.5rem;
      list-style: decimal;
      /* list-style-position: outside; */
      li {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }
        &::marker {
          font-size: 1.25rem;
        }
      }
    }
  }
`

const RecipeHeading = styled(Heading1)`
  position: absolute;
  bottom: 1rem;
  left: 1rem; 
  right: 1rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  background-color: ${colors.colorWhite};
  font-family: Amatic SC;
  margin: 0;
  @media ${device.mediaMinLarge} {
    right: auto;
  }
`

const MetaContent = styled.aside`
  background-color: ${colors.colorMeta};
  /* border-left: 1px black solid; */
  padding: 2rem;
  &> div:not(:last-child) {
    /* border-bottom: 1px solid ${colors.colorBlack}; */
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }

  .ingredients-block-content {
    p {
      background-color: #e2e2e2a6;
      border: solid 2px #e2e2e2;
      border-radius: 1rem;
      padding: 1rem;
      margin: 0 0 0.5rem;
    }
    ul {
      margin-left: 1rem;
    }
    ul:not(:last-child) {
      margin-bottom: 1rem;
      li {
        font-style: italic;
      }
    }
  }
`

const PublishedAt = styled.div`
  ${typography.small}
  margin: 0;
`

const MetaHeadline = styled(Heading3)`
  margin: 0.5rem 0 1rem;  
  padding-bottom: 0.5rem;
  border-bottom: 1px solid ${colors.colorBlack}; 
`

const StyledIngredients = styled.div`
  ul {
    padding: 0 0 0 1rem;
    list-style: disc;

    li {
      &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
    }
  }
`

const StyledCategories = styled.div`

  & ul {
    list-style: none;
    margin: 0.75rem 0;
    padding: 0;
  }

  & ul li {
    padding: 0.25rem 0;
  }
`

const RelatedRecipes = styled.div`

  & ul {
    list-style: none;
    padding: 0;

    li {
      &:not(:last-child) {
        border-bottom: 4px dotted #ccc;
        margin-bottom: 1rem;
      }
    }
  }

  & a {
    display: flex;
    flex-direction: column;
    color: inherit;
    text-decoration: none;
    margin-bottom: 1rem;
  }
`

const RecipeDescription = styled.div`
  text-align: center;
  padding: 3rem 2rem;
  border-bottom: solid 1px #ccc;
  p {font-size: 1.4rem;}
  @media ${device.mediaMinLarge} {
    padding: 6rem 4rem;
  }
`

const RelatedRecipesLink = styled(Link)`
  position: relative;
  h4 {
    box-shadow: 0.2rem 0.2rem 0.25rem #00000033;
    margin-top: -45px;
    margin-left: 1rem;
    margin-right: 1rem;
    background: #ffffffcc;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 1rem;
    transition: all 1s ease-out;
  }
  &:hover {
    h4 {
      text-decoration: underline;
      transform: translateX(-10px);
    }
  }
`

const MethodTitle = styled(Heading3)`
 margin: 0.5rem 0 1rem;
`

function Recipe (props) {
  const { _rawIngredients, _rawRecipeDescription, _rawBody, title, categories, mainImage, publishedAt, relatedRecipes } = props
  return (
    <RecipeWrapper>
      <Container style={{ paddingTop: '0' }}>
        {props.mainImage && mainImage.asset && (
          <MainImage>
            <img
              src={imageUrlFor(buildImageObj(mainImage))
                .width(1200)
                .height(Math.floor((9 / 16) * 1200))
                .fit('crop')
                .url()}
              alt={mainImage.alt}
            />
            <RecipeHeading>{title}</RecipeHeading>
          </MainImage>
        )}

        <RecipeDescription>{_rawRecipeDescription && <BlockContent blocks={_rawRecipeDescription || []} className='recipe-description' />}</RecipeDescription>

        <RecipeGrid>
          <MainContent>
            <MethodTitle>Method 🥣</MethodTitle>
            {_rawBody && <BlockContent blocks={_rawBody || []} className='recipe-block-content' />}
          </MainContent>
          <MetaContent>
            {/* {publishedAt && (
              <PublishedAt>
                Posted:&nbsp;
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </PublishedAt>
            )} */}
            {/* {members && <RoleList items={members} title='Authors' />} */}

            {_rawIngredients &&
              <StyledIngredients>
                <MetaHeadline>Ingredients:</MetaHeadline>
                <BlockContent blocks={_rawIngredients || []} className='ingredients-block-content' />
              </StyledIngredients>}

            {/* {categories && (
              <StyledCategories>
                <MetaHeadline>Categories</MetaHeadline>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </StyledCategories>
            )} */}

            {relatedRecipes && (
              <RelatedRecipes>
                <MetaHeadline>Related recipes</MetaHeadline>

                <ul>
                  {relatedRecipes.map(recipe => (
                    <li key={`related_${recipe._id}`}>
                      <RelatedSidebarItem
                        item={recipe}
                      />
                      {/* <RelatedRecipesLink to={`/recipe/${recipe.slug.current}`}>
                        {recipe.mainImage && recipe.mainImage.asset && (
                          <img
                            src={imageUrlFor(buildImageObj(recipe.mainImage))
                              .width(320)
                              .maxWidth(320)
                              .height(Math.floor((9 / 16) * 320))
                              .quality(100)
                              .sharpen(20)
                              .fit('crop')
                              .url()}
                            alt={recipe.mainImage.alt}
                          />
                        )}
                        <Heading4 className='related-recipes-title'>{recipe.title}</Heading4>
                      </RelatedRecipesLink> */}
                    </li>
                  ))}
                </ul>
              </RelatedRecipes>
            )}
          </MetaContent>
        </RecipeGrid>
      </Container>
    </RecipeWrapper>
  )
}

export default Recipe
