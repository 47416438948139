import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Recipe from '../components/recipe'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query RecipeTemplateQuery($id: String!) {
    recipe: sanityRecipe(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      relatedRecipes {
        title
        _id
        slug {
          current
        }
        mainImage {
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            _id
          }
          alt
        }
      }
      mainImage {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      title
      slug {
        current
      }
      _rawRecipeDescription
      _rawBody
      _rawIngredients
    }
  }
`

const RecipeTemplate = props => {
  const { data, errors } = props
  const recipe = data && data.recipe
  return (
    <>
      {errors && <SEO title='GraphQL Error' />}
      {recipe && <SEO title={recipe.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {recipe && <Recipe {...recipe} />}
    </>
  )
}

export default RecipeTemplate
